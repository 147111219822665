<template>
<v-layout justify-center>
      <v-flex xs12 sm12 md12 lg12 class="mt-12">
        <v-card flat color="background">
          <!-- Push Contents In and Put Space Between Flex Items Using Grid List -->
          <v-container fluid grid-list-lg>
            <single-toolbar-section title="Puntos de venta" placeholderSearch="Buscar" @searchItem="searchItemData"/>
            
            <v-layout row wrap v-if="items.length>0">
              <!--  Create Cards Based on Objects in an Array -->
              <v-flex class="card-item" 
              v-for="item in items" v-bind="{ [`lg4 md4 xl4 sm6`]: true }" 
              :key="item._id"
              >
                <v-card flat @click.native="showItemInfo(item)" class="elevation-4">
                  <v-list color="cards" rounded two-line>
                    <v-list-item>
                      <v-list-item-avatar>
                          <v-avatar size="60">
                            <v-img
                            :src="require('@/assets/app/img-default-pos.jpg')"
                            ></v-img>
                          </v-avatar>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title><b>{{item.name}}</b></v-list-item-title>
                        <v-list-item-subtitle>CLAVE: {{item.terminal_key}}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{item.description}}</v-list-item-subtitle>
                      </v-list-item-content>
                      
                      <v-list-item-action >
                        <v-menu bottom
                          v-if="!item.isCAR"
                          origin="center center"
                          transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                              icon
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on">
                                <unicon name="ellipsis-v" fill="#5A5A5A"></unicon>
                              </v-btn>
                            </template>
                            <v-list dense>
                              <v-list-item @click.stop="editItem(item)">
                                <v-list-item-title>Editar</v-list-item-title>
                              </v-list-item>
                              <v-list-item @click.stop="deleteItem(item)">
                                <v-list-item-title>Eliminar</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-flex>
            </v-layout>
            <no-results v-else />

            <confirm-modal ref="confirm" @interface="componentConfirmResult" />
            <terminal-info-drawer ref="drawerTerminal" @editionClicked="editItem"/>

          </v-container>
          <floating-button @clickFloating="addNewTerminal" icon="add" color="black"/>
        </v-card>
      </v-flex>

      <add-edit-terminal-modal ref="addEditModal"/>
    </v-layout>
</template>
<script>
  import axios from 'axios';
  import SingleToolbarSection from './Toolbars/SingleToolbarSection'
  import FloatingButton from './Toolbars/FloatingButton'
  import AddEditTerminalModal from './Dialogs/AddEditTerminalModal'
  import ConfirmModal from './Dialogs/ConfirmModal'
  import TerminalInfoDrawer from './Drawers/TerminalInfoDrawer'
  import NoResults from './NoResults'

  const REQUEST_ITEM_DELETE = 1;
  const REQUEST_ITEM_TOGGLE_ACTIVATE = 2;
  export default {
    components: {
      SingleToolbarSection,
      FloatingButton,
      AddEditTerminalModal,
      ConfirmModal,
      TerminalInfoDrawer,
      NoResults
    },
    data: () => ({
      items: [],
      currentSearch: '',
      publicPath: process.env.VUE_APP_URL
    }),

    created () {
      this.initialize();
      this.loadTerminalsListeners();
    },

    mounted() {
      //this.$store.dispatch('savePath', 'pos');
      this.$bus.$emit('selectCurrentMenuOption',4);
    },
    computed: {
      activeBusiness(){
        return this.$store.getters.business;
      },
    },
    methods: {
      filterWasChanged(selectedFilter){
        this.filterSelected = selectedFilter;
      },
        loadTerminalsListeners () {
              this.$bus.$off('refreshTerminalsList');

              this.$bus.$on('refreshTerminalsList', (isLoading) => {
                this.listTerminals();
              });
        },
        showItemInfo(item){
          this.$refs.drawerTerminal.show(item);
        },
        deleteItemConfirmed(item_id, isConfirmated){
          if(isConfirmated){
            this.$bus.$emit('toggleLoading', true);
            axios.post('terminals/delete',{item_id}).then(response => {
                this.$bus.$emit('toggleLoading', false);
                this.listTerminals();
            }).catch(err => {

            });
          }
        },
        componentConfirmResult(isConfirmated, item_id, component){
          switch (component) {
                case REQUEST_ITEM_DELETE :
                  this.deleteItemConfirmed(item_id, isConfirmated);
                break;
            }
        },
        editItem(item){
          const itemRow = {...item};
          this.$refs.addEditModal.edit(itemRow);
        },
        deleteItem(item){
          let data = {
              title: "Eliminar",
              message: `¿Deseas eliminar <<b>${item.name}</b>>? No podrás deshacer la acción.`,
              confirmText: "Si, Eliminar"
          }
          this.$refs.confirm.open(data, REQUEST_ITEM_DELETE, item._id);
        },
        addNewTerminal(){
          this.$refs.addEditModal.open();
        },
        searchItemData(search){
          this.currentSearch = search;
          this.listTerminals();
        },
        listTerminals(){
            let me=this;
            const userData = this.$store.state.user
            const search = this.currentSearch;
            this.$bus.$emit('toggleLoading', true);

            if(userData.role == "admin"){
              axios.get('users/query?_id='+userData._id)
              .then(function (responseUser){
                const businessId = responseUser.data.business ;
                axios.get(`terminals/list/${businessId}?search=${search}`).then(response => {
                  me.items = response.data;
                  me.$bus.$emit('toggleLoading', false);
                }).catch(err => {  });
              })
            }else{
              const businessId = this.activeBusiness._id;
              axios.get(`terminals/list/${businessId}?search=${search}`).then(response => {
                this.items = response.data;
                this.$bus.$emit('toggleLoading', false);
              }).catch(err => {  });
            }


            


        },
        initialize () {
            this.listTerminals();
        },
    },
  }
</script>
<style scoped>
.flex-empty div {
  width: 350px;
}
.card-item{
  min-width: 320px !important;
  /*flex-basis: initial !important;
  max-width: initial !important;*/
}
#card-container, .data-containe{
  height: 55vh;
  overflow: auto;
  width: 100%;
  padding-right: 0 !important;
  margin-right: 0 !important;
}
.v-list-item__action{
  margin-left: 0 !important;
}
</style>
<template>
<v-layout
          align-center
          justify-center
        >
    <v-card flat width="100vw">
      <v-toolbar
        extended
        flat
        style="height: 180px;"
      >
      </v-toolbar>
      
      <v-content>
        <snack-bar-message ref="snackMessages" :timeout=-1 :multi=true />
        <!-- Provides the application the proper gutter -->
        <!-- <v-container fluid> -->
        <v-container fluid v-if="businessData">
          <v-card
            class="mx-auto"
            max-width="100vw"
            style="margin-top: -200px;"
            flat
          >
            <v-toolbar dark
              height="300"
              :src="renderImage(businessData.cover_image)"
              style="transform: translateY(0);"
              >
            </v-toolbar>
  
            <v-divider></v-divider>
  
            <v-card-text style="padding: 0">
              <v-avatar 
              size="180"
              style="margin-top: -100px; margin-left: 100px;">
                    <img
                  :src="renderImage(businessData.logo)"
                  alt="image"
                >
                  </v-avatar>
                  <!-- max-width="800" -->
                  <v-card
                    class="mx-auto"
                    flat  
                    color="white"
                  >
                    <v-card-text>
                      <v-card
                        class="mx-auto"
                        flat  
                        max-width="800"
                      >
                        <v-card-text>
                          <v-badge
                          bordered
                          color="customdot"
                          bottom
                          dot
                          offset-x="0"
                          offset-y="14"
                            >
                          <h1>{{businessData.name}}</h1>
                          </v-badge>
                          <div id="app" v-show="isLoaded">Conekta cargado</div>
                          <p>
                            Oh. A bolt of lightning, unfortunately, you never know when or where it’s ever gonna strike. My god, do you know what this means? It means that this damn thing doesn’t work at all. George. Oh, what I meant to day was-
                          </p>
                          <p>
                            Uh, well, I gotta go. That Biff, what a character. Always trying to get away with something. Been on top of Biff ever since high school. Although, if it wasn’t for him- No no. Lorraine, Lorraine, what are you doing? Calvin, why do you keep calling me Calvin? Quiet down, I’m sure the car is fine.
                        </p>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                  </v-card>

                  <v-card
                    class="mx-auto"
                    color="cards"
                  >
                    <v-card-text>
                      <v-card
                        class="mx-auto"
                        flat  
                        max-width="800"
                        color="cards"
                      >
                        <v-card-text>
                          <v-badge
                          bordered
                          color="customdot"
                          bottom
                          dot
                          offset-x="0"
                          offset-y="14"
                            >
                          <h1>Pagos cashless</h1>
                          </v-badge>
                          <p>Queremos que <b>tu visita sea única y obtengas la mejor experiencia</b>, por eso usamos <b>Ciphra Cashless</b>, el método de pago más rápido y seguro.</p>
                          <p>A tu llegada, recibirás una pulsera que podrás recargar las veces que quieras y pagar con ella.</p>
                          <p>Abona online, deja el efectivo y tarjeta en casa, disfruta la visita y si quedó saldo, pide el reembolso. La cantidad abonada se transferirá a tu pulsera, previa comprobación de identidad. Si lo prefieres, puedes abonar hasta tu llegada.</p>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                  </v-card>

                <v-card
                    class="mx-auto"
                    flat  
                    color="white"
                  >
                    <v-card-text>
                      <v-card
                        class="mx-auto"
                        flat  
                        max-width="800"
                      >
                        <v-card-text>
                            <v-badge
                              bordered
                              color="customdot"
                              bottom
                              dot
                              offset-x="0"
                              offset-y="14"
                                >
                          <h1>Abonar</h1>
                          </v-badge>
                          <v-stepper alt-labels v-model="e1" non-linear>
                            <v-stepper-header flat>
                              <v-stepper-step step="1" :complete="e1 > 1">
                                Monto
                              </v-stepper-step>

                              <v-divider></v-divider>

                              <v-stepper-step step="2" :complete="e1 > 2">
                                Datos
                              </v-stepper-step>

                              <v-divider></v-divider>

                              <v-stepper-step step="3" :complete="e1 > 3">
                                Método
                              </v-stepper-step>
                              <v-divider></v-divider>

                              <v-stepper-step step="4" :complete="processCompleted">
                                Confirmación
                              </v-stepper-step>
                            </v-stepper-header>
                            <v-stepper-items>
                              <v-stepper-content step="1">
                                <v-card
                                  flat
                                  height="330px"
                                >
                                <p class="font-weight-bold">
                                  Selecciona el monto que deseas abonar
                                </p>
                                  <v-row class="text-center prepaid-cards space-around">
                                    <v-col md="4" v-for="(itemAmount, index) in prepaids_amounts" :key="itemAmount.amount+index">
                                      <v-card color="white" height="100px">
                                            <v-card-title 
                                            class="justify-center " 
                                            v-bind:class="{ active: amountSelected == itemAmount.amount }"
                                            @click.stop="selectAmount(itemAmount)">$ {{itemAmount.amount}}</v-card-title>
                                      </v-card>
                                    </v-col>
                                  </v-row>
                                  <p>Cantidades en pesos mexicanos (MXN).</p>
                                </v-card>
                              </v-stepper-content>
                              <v-stepper-content step="2">
                                <v-card
                                  flat
                                  height="330px"
                                >
                                  <p class="font-weight-bold">
                                    Completa los siguientes datos
                                  </p>
                                  <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                    <v-menu
                                      ref="menu1"
                                      v-model="menu1"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      max-width="290px"
                                      min-width="auto"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                          :value="computedDateFormattedMomentjs"
                                          readonly
                                          class="input-datapicker-table"
                                          label="Fecha de visita o llegada"
                                          persistent-hint
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="date"
                                        no-title
                                        color="primary"
                                        @change="dateSelected"
                                        @input="menu1 = false"
                                      >
                                      </v-date-picker>
                                    </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-text-field
                                        label="Email de contacto"
                                        v-model="paymentData.contactEmail"
                                        dense
                                        :rules="rules.validEmail"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-text-field
                                        label="Teléfono de contacto"
                                        v-model="paymentData.contactPhone"
                                        dense
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-card>
                              </v-stepper-content>
                              <v-stepper-content step="3">
                                <v-card
                                  flat
                                  height="330px"
                                >
                                  <p class="font-weight-bold">
                                    Selecciona el método de pago
                                  </p>
                                   <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-radio-group
                                        v-model="paymentData.method"
                                        row
                                        @change="payMethodChange"
                                      >
                                        <v-radio
                                          label="Tarjeta de crédito o débito"
                                          value="card"
                                        ></v-radio>
                                        <v-radio
                                          label="Pago en OXXO"
                                          value="cash"
                                        ></v-radio>
                                      </v-radio-group>
                                    </v-col>
                                   </v-row>
                                   <v-row v-if="paymentData.method == 'card'">
                                      <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                          label="Titular de la tarjeta"
                                          v-model="paymentData.cardOwner"
                                          dense
                                        ></v-text-field>
                                      </v-col>
                                       <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                          label="Número de tarjeta"
                                          v-model="paymentData.cardNumber"
                                          dense
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="2" sm="2" md="2">
                                        <v-select
                                        :items="validMonths"
                                        placeholder="Mes"
                                        v-model="paymentData.cardMonth"
                                        dense
                                      ></v-select>
                                      </v-col>
                                      <v-col cols="2" sm="2" md="2">
                                        <v-select
                                        :items="years"
                                        placeholder="Año"
                                        v-model="paymentData.cardYear"
                                        dense
                                      ></v-select>
                                      </v-col>
                                      <v-col cols="1" sm="1" md="1">
                                      </v-col>
                                      <v-col cols="2" sm="2" md="2">
                                        <v-text-field
                                          label="CVC"
                                          v-model="paymentData.cardCvc"
                                          maxlength="4"
                                          dense
                                        ></v-text-field>
                                      </v-col>
                                   </v-row>
                                   <v-row v-else>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-text-field
                                          label="Nombre completo para el recibo de pago"
                                          v-model="paymentData.cardOwner"
                                        ></v-text-field>
                                      <p>
                                      Al concluir, se generará un número de referencia para pagar en OXXO. 
                                    </p>
                                    </v-col>
                                   </v-row>
                                </v-card>
                              </v-stepper-content>
                              <v-stepper-content step="4">
                                <v-card
                                  flat
                                  height="330px"
                                >
                                <p class="font-weight-bold">
                                    Resumen de transacción
                                  </p>
                                  <v-row class="text-center prepaid-cards space-around">
                                    <v-col md="12">
                                      <p class="font-weight-bold">Cantidad a pagar</p>
                                      <p class="amount-resume">{{amountFormatted}}</p>
                                      <p class="amount-text">{{amountWords}}</p>
                                      <p class="font-weight-bold">Método de pago</p>
                                      <p class="payment-method">{{paymentData.method == 'card' ? 'Tarjeta de crédito o débito' : 'OXXO'}}</p>
                                      <v-checkbox
                                        v-model="termsAccepted"
                                        label="Para terminar debes aceptar los términos y condiciones de la transacción."
                                      ></v-checkbox>
                                    </v-col>
                                  </v-row>
                                </v-card>
                              </v-stepper-content>
                            </v-stepper-items>
                          </v-stepper>
                            
                          <!-- SI TODO EL PROCESO TERMINA BIEN -->
                          <v-card 
                                  flat
                                  color="white"
                                  :height="prepaidRow.reference ? 800: 330 "
                                v-if="processCompleted">
                                <v-row class="text-center prepaid-cards space-around" v-if="prepaidRow.reference">
                                    <v-col md="12">
                                      <oxxo-receipt :prepaid="prepaidRow"/>
                                    </v-col>
                                  </v-row>

                                  <v-row class="text-center prepaid-cards space-around" v-else>
                                    <v-col md="12">
                                      <v-avatar 
                                        style="margin-bottom: 30px;"
                                        size="100">
                                            <img
                                          :src="require('@/assets/app/img-success-sign.svg')"
                                          alt="success payment"
                                        >
                                      </v-avatar>
                                      <p class="font-weight-bold">¡Tu pago fue realizado con éxito!</p>
                                      <p class="font-weight-bold">Clave de abono {{ prepaidRow.prepaid_code }}</p>
                                      <p class="disclaimer-payment">
                                        En breve recibirás un correo con la confirmación de pago.<br>
                                        Al llegar al Centro de Activación y Recargas, menciona la clave de abono para transferir el saldo a tu pulsera.
                                      </p>
                                      <p>Si no recibes el correo en los próximos 10 minutos, checa la bandeja de spam.</p>
                                    </v-col>
                                  </v-row>
                                  
                                </v-card>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn dark v-if="e1>1 && !processCompleted"
                                class="elevation-0"
                                color="menu"
                                rounded
                                @click.native="prevStep()">
                                  Regresar
                          </v-btn>
                          <v-btn
                              class="white--text"
                                color="primary"
                                :disabled="!isOkStep"
                                rounded
                                @click.native="nextStep()">
                              {{labelNext}}
                          </v-btn>
                      </v-card-actions>
                      </v-card>
                    </v-card-text>
                  </v-card>

                  <v-card
                    class="mx-auto"
                    flat  
                    color="cards"
                    style="padding-bottom: 50px;"
                  >
                    <v-card-text>
                      <v-card
                        class="mx-auto"
                        flat  
                        max-width="800"
                        color="cards"
                      >
                        <v-card-text>
                            <v-badge
                              bordered
                              color="customdot"
                              bottom
                              dot
                              offset-x="0"
                              offset-y="14"
                              >
                              <h1>FAQs</h1>
                            </v-badge>
                            <v-expansion-panels accordion>
                              <v-expansion-panel>
                                <v-expansion-panel-header>¿Qué beneficios obtengo?</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <p>Al pagar con Ciphra Cashless obtendrás:</p>
                                  <ul>
                                      <li>Mayor seguridad contra robo de efectivo o clonación de tarjeta.</li>
                                      <li>Más rapidez al pagar, evitando largas filas.</li>
                                      <li>Mejor control de tu presupuesto.</li>
                                      <li>Menor riesgo sanitario reduciendo el uso de efectivo.</li>
                                  </ul>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                              <v-expansion-panel>
                                <v-expansion-panel-header>¿Podré pagar con efectivo y tarjeta además de la pulsera?</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <p>Solo podrás usar efectivo o tarjeta para recargar tu pulsera, los comercios al interior del inmueble únicamente aceptarán pagos con pulsera.</p>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                              <v-expansion-panel>
                                <v-expansion-panel-header>¿Qué pasa si no uso todo el dinero que aboné?</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <p>Podrás solicitar el reembolso cubriendo una comisión de $15 MXN, misma que se restará del saldo en la pulsera.</p>
                                  <p>Si el saldo no cubre la comisión, la solicitud no podrá procesarse y la cantidad permanecerá en tu pulsera por tiempo limitado.</p>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                              <v-expansion-panel>
                                <v-expansion-panel-header>¿Qué pasa si pierdo mi pulsera?</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <p>El dinero no podrá reponerse porque se requiere la pulsera para rastrear las operaciones y recuperar el saldo, pero puedes adquirir otra pagando la reposición y seguir comprando. Las pulseras no pueden bloquearse, por eso es importante ajustarla a tu mano y no retirar los broches de seguridad.</p>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                              <v-expansion-panel>
                                <v-expansion-panel-header>¿Cuánto dinero puedo abonar a la pulsera?</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <p>Si la recarga es online, puedes abonar desde $150 hasta $5,000 MXN. Si es on-site (lugar del recinto), podrás recargar desde $1 hasta el máximo permitido en el Centro de Activación y Recargas.</p>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                          </v-expansion-panels>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                  </v-card>
                  
            </v-card-text>
          </v-card>
        </v-container>
      </v-content>
    </v-card>
    <v-footer app color="black">
      <v-img
              :src="require('@/assets/app/logo-ciphra-white.svg')"
              contain
              height="50"
            />
    </v-footer>
  </v-layout>
</template>
<script>
  import axios from 'axios';
  import { format, parseISO } from 'date-fns'
  import writtenNumber from 'written-number';
  import SnackBarMessage from './Dialogs/SnackBarMessage.vue';
  import OxxoReceipt from './Modules/OxxoReceipt';

  export default {
    components: { 
      SnackBarMessage,
      OxxoReceipt,
    },
    data: () => ({
      public_key_conekta: process.env.VUE_APP_CONEKTA_ENV == 'development' ? process.env.VUE_APP_CONEKTA_PUBLIC_KEY_SANDBOX : process.env.VUE_APP_CONEKTA_PUBLIC_KEY_PROD,
      publicPath: process.env.VUE_APP_URL,
      isLoaded: false,
      date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      menu1: false,
      business: null,
      e1: 1,
      label_save_next: 'Siguiente',
      amountSelected: 0,
      prepaids_amounts: [
        {amount: 3, active: false},
        {amount: 250, active: false},
        {amount: 500, active: false},
        {amount: 1000, active: false},
        {amount: 2000, active: false},
        {amount: 5000, active: false},
      ],
      validMonths: ['01','02','03','04','05','06','07','08','09','10','11','12'],
      menu1: false,
      termsAccepted: false,
      paymentData: {
        method: 'card',
        contactEmail: null,
        contactPhone: null,
        cardOwner: null,
        cardNumber: null,
        cardMonth: null,
        cardYear: null,
        cardCvc: null,
        token: null
      },
      prepaidRow: null,
      rules: {
            required: [
                v => !!v || 'Campo requerido.',
            ],
            validEmail: [
                (v) => !!v || 'El campo es requerido',
                (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(v) || 'Email no válido'
            ],
        }
    }),
    computed:{
      isOkStep(){
        let isOk = false;
        switch(this.e1){
          case 1:
            isOk = this.amountSelected > 0;
          break;
          case 2:
            isOk = this.paymentData.contactEmail && this.paymentData.contactPhone;
          break;
          case 3:
            if(this.paymentData.method == 'card'){
              isOk = this.paymentData.cardNumber && this.paymentData.cardOwner &&
                    this.paymentData.cardMonth && this.paymentData.cardYear && this.paymentData.cardCvc;
            }else{
              isOk = this.paymentData.cardOwner;
            }
          break;
          case 4:
            isOk = this.termsAccepted;
          break;
          default:
            isOk = true;
          break;
        }

        return isOk;
      },
      processCompleted(){
        return this.prepaidRow;
      },
      labelNext(){
        let labelBtn = 'Siguiente';
        if(this.e1 == 4){
            labelBtn = 'Pagar';
        }else if(this.e1 > 4){
          labelBtn = 'Terminar';
        }
        return labelBtn;
      },
      amountFormatted(){
        return new Intl.NumberFormat("en-US",{
          style: 'currency',
          currency: 'USD',
          }).format(this.amountSelected);
      },
      amountWords(){
        writtenNumber.defaults.lang = 'es';
        return writtenNumber(this.amountSelected) + ' pesos MXN'; // => 'mil doscientos treinta y cuatro'
      },
      years () {
        const rangeOfYears = (start, end) => Array(end - start + 1)
        .fill(start)
        .map((year, index) => year + index)

        const currentYear = new Date().getFullYear();
        return rangeOfYears(currentYear, currentYear + 10);
      },
      computedDateFormattedMomentjs () {
        return this.date ? this.dateHelpers.formatInput(this.date) : ''
      },
      businessData(){
        return this.business;
      }
    },
    methods: {
      loadConekta: function () {
      const script = document.createElement("script");
      script.src = "https://cdn.conekta.io/js/latest/conekta.js";
      script.id = "conekta-script";

      const self = this;
      script.onload = function () {
        if (!window.Conekta) return void console.error("Conekta is not set");
        self.isLoaded = true;
      };

      script.async = true;
      document.body.appendChild(script);
    },
      dateSelected(date){
        console.log(date);
        //this.listRefunds();
      },
      selectAmount(itemAmount){
        this.amountSelected = itemAmount.amount;
      },
      payMethodChange(value){
        console.log(value);
      },
      goToStep(step){
          //Se valida que en la primera edicion no pueda saltar entre pasos
          //para que asegurar que esten llenos todos los datos requeridos
          const isPrevStep = step < this.e1;
          if(isPrevStep){
              this.e1 = step;
          }
      },
      prevStep(){
          //if(this.$refs.formCompanyRegister.validate() && this.e1 > 1){
          if(this.e1 > 1){ //Solo validar datos correctos en boton siguiente
              this.e1 = this.e1 - 1
          }
      },
      nextStep () {
          if(this.e1 == 3){//Tokenizar tarjeta para validar
            if(this.paymentData.method == 'card'){
              this.cardTokenize();
            }else{
              this.e1 = this.e1 + 1
            }
          }else if(this.e1 < 4){
              this.e1 = this.e1 + 1
          }else if(this.e1 == 4){
              this.processPayment();
          }else{
            this.resetPayment();
          }
      },
      resetPayment(){
        this.e1 = 1;
        this.amountSelected = 0;
        this.termsAccepted = false;
        this.paymentData = {
          method: 'card',
          contactEmail: null,
          contactPhone: null,
          cardOwner: null,
          cardNumber: null,
          cardMonth: null,
          cardYear: null,
          cardCvc: null,
          token: null
        };
        this.prepaidRow = null;
      },
      cardTokenize(){
        this.$bus.$emit('toggleLoading', true);
        console.log(process.env.VUE_APP_CONEKTA_ENV);
        console.log(this.public_key_conekta);
        window.Conekta.setPublicKey(this.public_key_conekta)
        const tokenParams = {
          card: {
            number: this.paymentData.cardNumber,
            name: this.paymentData.cardOwner,
            exp_year: this.paymentData.cardYear,
            exp_month: this.paymentData.cardMonth,
            cvc: this.paymentData.cardCvc
          }
        };
        //Tokenizamos la tarjeta
      window.Conekta.Token.create(tokenParams,token => {
        console.log(token);
        this.paymentData.token = token;
        this.e1 = this.e1 + 1
        this.$bus.$emit('toggleLoading', false);
      },error => {
        this.paymentData.token = null;
        this.$refs.snackMessages.show(error.message_to_purchaser);
        console.log(error);
        this.$bus.$emit('toggleLoading', false);
      });
      },
      processPayment(){
        
        const dataOfPayment = {
            user_name: this.paymentData.cardOwner,
            user_email: this.paymentData.contactEmail,
            user_phone: this.paymentData.contactPhone,
            visit_date: this.date,
            payment_method: this.paymentData.method,
            business_key: this.businessData.business_key,
            amount: this.amountSelected,
            token: this.paymentData.token
        };

        this.$bus.$emit('toggleLoading', true);
          axios.post('prepaids/processPayment',dataOfPayment).then(response => {
              this.$bus.$emit('toggleLoading', false);
              this.e1 = 5;
              this.prepaidRow = response.data;
          }).catch(error => {
              const details = error.response.data.error.details;
              const errors = details.map(errorDetail => {
                return errorDetail.message;
              })
              this.$refs.snackMessages.show(errors.join('<br/>'));
              this.$bus.$emit('toggleLoading', false);
          });
      },
      renderImage(image){
          const imgPath = this.publicPath+'images/uploads/business/' + image;
        return imgPath;
      },
      checkBusiness(){
        const businessUrl = this.$route.params.business_url;
        this.$bus.$emit('toggleLoading', true);
        axios.get(`business/url/${businessUrl}`)
        .then(response => {
           this.business = response.data;
           this.$bus.$emit('toggleLoading', false);
        }).catch(e => {
          console.log('missing business');
          
          this.$bus.$emit('toggleLoading', false);
        });
      },
    },
    created() {
    if (!this.isLoaded)
       this.loadConekta();
  },
    mounted(){
      this.checkBusiness();
      if (this.isLoaded)
       window.Conekta.setPublicKey(this.public_key_conekta);
    }
  }
</script>
<style scoped>
.container{
  padding: 0 !important;
}
.container *{
font-family: "Montserrat";
}
>>> .v-card__text *:not(.v-btn__content){
  color: #000000;
}
h1{
  font-family: "Montserrat";
  font-size: 40px;
}
p{
  font-family: "Montserrat";
}

.v-card__text .v-badge{
  margin-bottom: 40px;
}
.v-card__text .v-badge:not(:first-child){
  margin-top: 50px !important;
}
.v-expansion-panels{
  font-family: "Montserrat";
}
.v-expansion-panel button{
  font-weight: bold;
}

>>> .v-stepper{
  background: transparent !important;
  box-shadow: none !important;
}
>>> .v-main.v-content{
  margin-bottom: -50px !important
}
.prepaid-cards{
  margin: -10px !important;
}
.prepaid-cards .v-card .v-card__title{
  font-weight: bold;
  height: 100% !important;
  cursor: pointer;
}
.prepaid-cards .v-card .v-card__title.active{
  border: 1px solid #7954C2;
  color: #7954C2;
}
.v-stepper__header{
  box-shadow: none !important;
}
.amount-resume{
  color: #7954C2 !important;
  font-size: 40px;
  font-weight: bold;
  margin-top: 50px;
}
.amount-text{
  font-size: 20px;
  margin-bottom: 50px;
}
.payment-method{
  text-transform: uppercase;
  border-bottom: 1px solid #D8D8D8;
  width: 50%;
  margin: 0 auto;
  color: #5A5A5A !important;
}

</style>
<template>
    <v-navigation-drawer
      v-model="opened"
      height="100vh"
      right
      floating
      fixed
      temporary
      hide-overlay
      class="drawer-container"
    >
      <v-card flat v-if="business">
        <v-toolbar dense flat>
            <v-toolbar-title>Detalles</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="opened = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <v-expansion-panels 
                v-model="activePanel"
                flat accordion class="panels-drawer"
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Generales
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-list>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Creado</v-list-item-title>
                                    <v-list-item-subtitle>{{ business.createdAt | moment("DD MMMM YYYY") }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Nombre del negocio</v-list-item-title>
                                    <v-list-item-subtitle>{{ business.name }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Clasificación</v-list-item-title>
                                    <v-list-item-subtitle>{{ business.category.name }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Perfil público</v-list-item-title>
                                    <v-list-item-subtitle>    
                                        <v-btn color="link" text :href="urlPagos+business.url" link target="_blank">{{urlPagos+business.url}}</v-btn>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            
                        </v-list>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Descripción
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-list>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Descripción del evento o negocio</v-list-item-title>
                                    <v-list-item-subtitle>{{ business.description }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Domicilio
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-list>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Domicilio</v-list-item-title>
                                    <v-list-item-subtitle>{{ business.address }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Código postal</v-list-item-title>
                                    <v-list-item-subtitle>{{ business.postal_code }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Ciudad</v-list-item-title>
                                    <v-list-item-subtitle>{{ business.city }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Estado</v-list-item-title>
                                    <v-list-item-subtitle>{{ business.state }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>País</v-list-item-title>
                                    <v-list-item-subtitle>{{ business.country }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Facturación
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-list>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Razón social</v-list-item-title>
                                    <v-list-item-subtitle>{{ business.legal_entity }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Clave de negocio</v-list-item-title>
                                    <v-list-item-subtitle>{{ business.business_key }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>RFC</v-list-item-title>
                                    <v-list-item-subtitle>{{ business.rfc }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Ciudad</v-list-item-title>
                                    <v-list-item-subtitle>{{ business.city }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Uso de CFDI</v-list-item-title>
                                    <v-list-item-subtitle>{{ business.invoice_usage.name }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Domicilio fiscal</v-list-item-title>
                                    <v-list-item-subtitle>{{ business.tax_residence }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Email de facturación</v-list-item-title>
                                    <v-list-item-subtitle>{{ business.invoice_email }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
            <v-row
                    align="center"
                    justify="space-around"
                >
                <v-btn
                    outlined
                    rounded
                    color="primary"
                    @click.stop="editItem"
                    >
                    Editar
                    </v-btn>
            </v-row>
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>
</template>
<script>
export default {
    name: 'BusinessInfoDrawer',
    data: () => ({
        urlPagos: process.env.VUE_APP_URL_PAGOS,
        opened: false,
        userLocked: false,
        business: null,
        activePanel: 0
    }),
    methods:{
        editItem(){
            this.$emit('editionClicked', this.business); 
        },
        show(business){
            this.opened = true;
            this.business = business;
        }
    }
}
</script>
<style scoped>
.drawer-container{
    width: 350px !important;
}
>>> .link--text .v-btn__content{
    color: #000000 !important;
}
</style>
<template>
   <div class="section-container">
    <div class="section-header">
      <h1  class="section-title">
        Catálogo de Productos
      </h1>

      <div class="section-filter">
        <v-text-field
          class="section-search"
          hide-details
          placeholder="Buscar"
          single-line
          v-model="currentSearch"
          clearable>
          <template v-slot:append>
            <unicon name="search" fill="#5A5A5A"></unicon>
          </template>
        </v-text-field>
        <div class="section-filter-down">
          <div class="section-filter-element">
            <span>POS:</span>
            <v-select
              class="select-filter"
              dense
              v-model="selected_filter_pos"
              :items="filter_pos"
              item-text="text"
              item-value="value">
            </v-select>
          </div>
        </div>
      </div>
    </div>

    <div class="section-table">
      <v-data-table
        :headers="headers"
        :items="products"
        :items-per-page="products.length"
        hide-default-footer>
        <template #item="{ item }">
          <tr>
            <td>
              <img src="../assets/app/img-default-pos.jpg"
              class="img-default-post-table" />
            </td>
            <td>{{ item.prod_name }}</td>
            <td>{{ formattedDate(item.updated_at) }}</td>
            <td>{{ item.prod_sku }}</td>
            <td>
              <img v-if="item.available_pos" 
              src="../assets/app/check-circle.svg"
              class="img-status-table"/>
              <img v-if="!item.available_pos" 
              src="../assets/app/uncheck-circle.svg"
              class="img-status-table"/>
            </td>
            <td>
              <img v-if="item.available_so" 
              src="../assets/app/check-circle.svg"
              class="img-status-table"/>
              <img v-if="!item.available_so" 
              src="../assets/app/uncheck-circle.svg"
              class="img-status-table"/>
            </td>
            <td>{{ formattedPrice(item.prod_price) }}</td>
            <td>{{ formattedPrice(item.promo_price) }}</td>
            <td>
              <v-menu bottom
                origin="center center"
                transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on">
                    <unicon name="ellipsis-v" fill="#5A5A5A"></unicon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item @click="openDialogProduct(item)">
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="deleteModal(item)">
                    <v-list-item-title>Eliminar</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
     
      </v-data-table>
      
      <div v-if="showBtnMore"  class="btnMoreData" >
        <span @click="getProducts(true)">Mostrar mas +</span>
      </div>
      
    </div>

    <floating-button 
      @clickFloating="openDialogProduct(null)" 
      icon="add" color="black"/>

    <add-edit-catalog 
      ref="addEditModal" 
      :terminals="terminals"/>

    <confirm-modal 
      ref="confirm" 
      @interface="componentConfirmResult" />

  </div>
</template>

<script>
  import axios from 'axios';
  import FloatingButton from './Toolbars/FloatingButton'
  import ConfirmModal from './Dialogs/ConfirmModal'
  import AddEditCatalog from './Dialogs/AddEditCatalog.vue';

  const REQUEST_ITEM_DELETE = 1;

  export default {
    components: {
      FloatingButton,
      ConfirmModal, 
      AddEditCatalog
    },
    name: 'CyphraWebCatalog',
    data() {
      return { 
        currentSearch: '',
        selected_filter_pos: "todos",
        filter_pos: [
            {value: 'todos', text: 'Todos'},
        ],
        headers: [
          { text: '', value: 'url' },
          { text: 'Producto', value: 'prod_name' },
          { text: 'Editado', value: 'updated_at' },
          { text: 'Código', value: 'prod_sku' },
          { text: 'Visible POS', value: 'available_pos'},
          { text: 'Visible SO', value: 'available_so'},
          { text: 'Precio', value: 'prod_price'},
          { text: 'Precio Esp.', value: 'promo_price'},
          { text: 'Acciones', value: '_id'},
        ],
        
        terminals:[],
        products: [],
        businessId:"",
        currentPage:1,
        totalPage:0, 
        showBtnMore:false
      };
    },
    watch: {
      selected_filter_pos(newVal, oldVal) {
        this.getProducts(false)  
      },
      currentSearch(newVal, oldVal) {
        this.getProducts(false)  
      },
    },
    mounted() { 
      this.$bus.$emit('selectCurrentMenuOption',10);
      this.getInfo();
      
    },
    created () {
      this.loadListeners();
    },
    methods: { 

      loadListeners () {
        this.$bus.$off('refreshCatalogList');
        this.$bus.$on('refreshCatalogList', (isLoading) => {  
          this.getInfo();
        });
      },

      openDialogProduct(item) {
        this.terminals = [...this.filter_pos]
        this.terminals.shift()
        this.$refs.addEditModal.open(item,this.businessId)
      },

      deleteModal(item){
        let data = {
          title: "Eliminar producto",
          message: `¿Deseas eliminar <b>${item.prod_name}</b>? No podrás deshacer la acción.`,
          confirmText: "Si, Eliminar"
        }
        this.$refs.confirm.open(data, REQUEST_ITEM_DELETE, item._id);
      },

      async getInfo(){
        const me = this
        me.products=[]
        const userData = this.$store.state.user
        if(userData.role == "admin"){
          await axios.get('users/query?_id='+userData._id)
          .then(function (responseUser){
            me.businessId = responseUser.data.busines
          })
        }else{
          me.businessId = (this.$store.state.business)._id
        }
        me.getPostList( me.businessId)
      },

      getPostList(bussinesId){
        const me = this
        axios.get('terminals/listpost?business_id='+bussinesId)
        .then( function (responsePos){
          for(const item of responsePos.data){
            me.filter_pos.push({
              value: item._id, text: item.name
            })
            
          }
          me.getProducts(false)
        })
      }, 

      getProducts(isMore){
        const me = this
        let urlApi = ''
        let page = isMore ? this.currentPage + 1 : 1

        if(this.selected_filter_pos == "todos"){
         urlApi = 'products/listByBusiness?id=' + this.businessId +
                  '&page=' + page +
                  '&limit=20' +
                  '&search=' + this.currentSearch
        }else{
          urlApi = 'products/listByPos?terminal_id=' + this.selected_filter_pos +
                  '&page=' + page +
                  '&limit=20' +
                  '&search=' + this.currentSearch
        }

        axios.get(urlApi)
        .then(function (responseProducts){
          if(!isMore) me.products = []
             
          me.totalPage = responseProducts.data.totalPages 
          me.currentPage = responseProducts.data.currentPage 
          for(const item of responseProducts.data.products){
            me.products.push(item)
          }

          if(me.totalPage == me.currentPage) me.showBtnMore= false
          else me.showBtnMore= true
        })  
      },

      formattedDate(date){
        const fDate = this.dateHelpers.formatTable(date)
        return fDate;
      },

      formattedPrice(price){
        if (price === null || price === undefined) {
            return '--';
        }
        return `$${price.toFixed(2)}`;
      },

      componentConfirmResult(isConfirmated, item_id, component){
        switch (component) {
          case REQUEST_ITEM_DELETE :
            this.deleteProduct(item_id, isConfirmated);
          break;
        }
      },

      deleteProduct(item_id, isConfirmated) {
        if(isConfirmated){
          const userData = this.$store.state.user
          const bodyData = {
            edited_by:userData._id,
            _id:item_id
          }
          axios.post('products/delete',bodyData).then(response => {
            this.getInfo();
          }).catch(error => {});
        }    
      },

    },
  };
</script>


<style scoped lang="scss">
  @import './../assets/styles/sections-app.css';

  .img-default-post-table{
    width: 40px;
    height: 40px;
    border-radius: 6px;
  }

  .img-status-table{
    width: 22px;
    height: 22px;
  }
</style>
<template>
  <v-dialog 
    v-model="dialogOpen"
    persistent
    max-width="640">
      <v-card class="form-tabs">
        <v-toolbar color="white" flat>
          <v-toolbar-title>
            {{isEdit ? 'Editar producto':'Agregar producto'}}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogOpen = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form 
            ref="formItem" 
            @submit.prevent="submit"
            v-model="validData">
            <v-container class="container-form">

              <div class="form-row" style="margin-bottom: 20px;">

                <div class="container-input-image">
                  <img :src="imageUrl" class="img-selected-form" />
                  <div class="button-add-img" @click="selectFile">
                    <v-icon color="white" x-large>add</v-icon>
                  </div>
                  <input type="file" 
                    ref="fileInput"
                    @change="onFileChange" 
                    accept="image/*"
                    style="display: none;"/>
                </div>

                <div class="section-swich-form">

                  <div class="container-input-switch">
                    <div class="title-switch-component" >
                      <h1> Disponible en POS</h1>
                      <h3> Visible en puntos de venta </h3>
                    </div>
                    <v-switch
                      v-model="isPos"
                      color="black"
                      hide-details
                    ></v-switch>
                  </div>

                  <div class="container-input-switch">
                    <div class="title-switch-component" >
                      <h1> Disponible en Self-Ordering 1</h1>
                      <h3> Visible en kioscos y app comprador </h3>
                    </div>
                
                    <v-switch
                      v-model="isSO"
                      color="black"
                      hide-details
                      
                    ></v-switch>
                  </div>

                </div>
                
              </div>
            
              <v-text-field
                  style="margin-bottom: 10px;"
                  v-model="nameProduct"
                  :rules="rules.required"
                  label="Nombre del producto*"
                  required
                  dense>
              </v-text-field>

              <v-text-field
               style="margin-bottom: 10px;"
                  v-model="descriptionProduct"
                  label="Descripción"
                  dense>
              </v-text-field>

              <div class="form-row" style="margin-bottom: 10px;" >
                
                <v-text-field
                  style="max-width: 48%;"
                  v-model="skuProduct"
                  label="Código del producto (SKU)*"
                  :rules="rules.required"
                  required
                  dense>
                </v-text-field>
                <v-select
                    style="max-width: 48%;"
                    v-model="postSelected"
                    :items="terminals"
                    item-text="text"
                    item-value="value"
                    label="Terminal de venta u operación"
                    dense
                    :rules="rules.required">
                </v-select>
              </div>

              <div class="form-row"  style="margin-bottom: 10px;">
                <v-text-field
                  style="max-width: 48%;"
                  v-model="price"
                  label="Precio unitario con IVA*"
                  :rules="rules.required"
                  required
                  dense>
                </v-text-field>
                <v-text-field
                  style="max-width: 48%;"
                  v-model="discount"
                  label="Precio especial (rebajado)"
                  :rules="rules.required"
                  required
                  dense>
                </v-text-field>
              </div>

              <div class="form-row"  style="margin-bottom: 10px;">
                <v-select
                    style="max-width: 48%;"
                    v-model="categorySelected"
                    :items="categories"
                    item-text="text"
                    item-value="value"
                    label="Seleccionar categorías*"
                    dense
                    :rules="rules.required">
                </v-select>
                <v-select
                    style="max-width: 48%;"
                    v-model="timeSelected"
                    :items="time"
                    item-text="text"
                    item-value="value"
                    label="Tiempo de preparación*"
                    dense
                    :rules="rules.required">
                </v-select>
              </div>


            </v-container>
          </v-form>
        </v-card-text>
        
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="reset(); dialogOpen = false">
            Cancelar
          </v-btn>
          <v-btn
            color="black"
            rounded
            :dark="validData"
            :loading="isSavingData"
            :disabled="!validData"
            @click="saveData()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    <snack-bar-message ref="snackMessages"/>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import SnackBarMessage from './SnackBarMessage.vue';


export default {
  props: ["terminals"],
  components: {
      SnackBarMessage
  },

  name: 'CyphraWebAddEditCatalog',
  data() {
    return {
      dialogOpen: false,
      isEdit: false,
      validData:false,
      isSavingData:false,

      idProduct:"",
      imageData:  null,
      imageUrl: require('../../assets/app/upload-img.svg'),
      isPos:true,
      isSO:false,
      nameProduct:"",
      descriptionProduct:"",
      skuProduct:"",
      postSelected:"",
      price:"",
      discount:"",
      categorySelected:"",
      timeSelected:"",
      categories:[],
      businessId:"",
      time:[
        { value:"0", text:"Inmediato" },
        { value:"1", text:"Menos de 5 minutos" },
        { value:"2", text:"5 a 10 minutos" },
        { value:"3", text:"10 a 20 minutos" },
        { value:"4", text:"20 a 30 minutos" },
        { value:"5", text:"Más de 30 minutos" },
      ],
      rules: {
        required: [
          v => !!v || 'Campo requerido.',
        ],
      }
    };
  },
  
  mounted() {},
  watch: {
    postSelected(newVal, oldVal) {
      if(newVal != ""){
        const me = this
        me.categories = []
        axios.get('categories/list?terminal_id='+newVal)
        .then(function (responseCategories){
          for(const item of responseCategories.data){
            me.categories.push({
              text:item.category_name,
              value:item._id
            } )
          }
        } )

      }
    },
  },
  methods: { 


    selectFile() {
      this.$refs.fileInput.click();
    },

    open(item, business){
    
      this.dialogOpen = true;
      if(item){
        this.isEdit = true
        this.nameProduct=item.prod_name
        this.skuProduct=item.prod_sku
        this.postSelected=item.terminal_id
        this.price=item.prod_price
        this.discount=item.promo_price
        this.isPos=item.available_pos
        this.isSO=item.available_so
        this.descriptionProduct=item.prod_description
        this.categorySelected=item.categories
        this.timeSelected=item.prep_time
        this.idProduct= item._id
        this.getImage(item._id)
      }else{
        this.businessId = business
        this.isEdit = false
      }
    },

    getImage(id){
      const me = this
      axios.get('files/image?linked_to='+id)
      .then(function (response){
        me.imageUrl = response.data.url
      })

    },

    saveData(){ 

      console.log(this.businessId)
      const me = this
      const userData = this.$store.state.user
      const saveUrl = this.isEdit ? 'products/update' : 'products/add';
      const bodyData = this.isEdit ? {
        edited_by:userData._id,
        available_pos:this.isPos,
        available_so:this.isSO,
        prod_name:this.nameProduct,
        prod_description:this.descriptionProduct,
        prod_sku:this.skuProduct,
        terminal_id:this.postSelected,
        categories:this.categorySelected,
        prod_price:this.price,
        promo_price:this.discount,
        prep_time:this.timeSelected,
        _id:this.idProduct
      } :{
        edited_by:userData._id,
        available_pos:this.isPos,
        available_so:this.isSO,
        prod_name:this.nameProduct,
        prod_description:this.descriptionProduct,
        prod_sku:this.skuProduct,
        terminal_id:this.postSelected,
        categories:this.categorySelected,
        prod_price:this.price,
        promo_price:this.discount,
        prep_time:this.timeSelected,
        buiness_id:this.businessId,
    } 

     // console.log(bodyData)

      
      axios.post(saveUrl,bodyData).then(async response => {

        if(me.imageData != null ){
          const formData = new FormData();
          formData.append('file', this.imageData);
          formData.append('name', response.data._id + "PDT"); // Agregar un nombre al archivo
          formData.append('uploaded_by',userData._id);
          formData.append('linked_to', response.data._id);
          
          try {
            const response = await axios.post('files/upload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data' // Especificar que es un envío de archivo
              }
            });
            this.isSavingData = false;
            this.reset();
            this.$bus.$emit('refreshCatalogList', true);
            this.dialogOpen = false;
          } catch (error) {
            console.error('Error al enviar el archivo', error);
          }

        }else{
          this.isSavingData = false;
          this.reset();
          this.$bus.$emit('refreshCatalogList', true);
          this.dialogOpen = false;
        }

        
    
      }).catch(err => {
          this.isSavingData = false;
          this.$refs.snackMessages.show('Ocurrio un error al guardar. Asegurese llenar todos los campos requeridos.');
      });
      


    },
    reset () { 
      this.isEdit = true
      this.imageData= null
      this.imageUrl= require('../../assets/app/upload-img.svg'),
      this.isPos=true,
      this.isSO=false,
      this.nameProduct=""
      this.descriptionProduct=""
      this.skuProduct=""
      this.postSelected=""
      this.price=""
      this.discount=""       
      this.categorySelected=""
      this.timeSelected=""
      this.idProduct= ""
    },
    onFileChange(event) {
      const file = event.target.files[0]; // Obtiene el archivo seleccionado
      if (file && file.type.startsWith('image/')) {
        this.imageData = file
        this.imageUrl = URL.createObjectURL(file);  // Crea una URL de la imagen para vista previa
      } else {
        alert("Por favor selecciona un archivo de imagen válido.");
      }
    },
  },
};
</script>

<style  scoped>
 @import './../../assets/styles/custom-input.css';

.container-form{
  display: flex;
  flex-direction: column;
}
.form-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.section-swich-form{
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 155px;
  margin-left: 35px; 
  justify-content: space-between;
}



.form-tabs .v-tabs-items{
    overflow: hidden !important;
    padding-top: 10px !important;
    height: 325px !important;
}
.form-tabs .row{
    margin: initial !important;
}
.form-tabs textarea{
    height: 230px !important;
}
.v-text-field__prefix{
    color: #000000 !important;
    font-weight: bold !important;
}

</style>
<template>
    <v-navigation-drawer
      v-model="opened"
      height="100vh"
      right
      floating
      fixed
      temporary
      hide-overlay
      class="drawer-container"
    >
      <v-card flat v-if="terminal">
        <v-toolbar dense flat>
            <v-toolbar-title>Detalles</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="opened = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <v-list>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Creado</v-list-item-title>
                        <v-list-item-subtitle>{{ terminal.createdAt | moment("DD MMMM YYYY") }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Punto de venta</v-list-item-title>
                        <v-list-item-subtitle>{{ terminal.name }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Descripción</v-list-item-title>
                        <v-list-item-subtitle>{{ terminal.description }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Colaboradores asociados</v-list-item-title>
                        <v-list-item-subtitle 
                        v-for="colaborator in terminal.collaborators"
                        :key="colaborator._id">{{ colaborator.name }}</v-list-item-subtitle>
                        <!-- <v-list-item-subtitle>app.cyphra.cc/pagos/{{ business.url }}</v-list-item-subtitle> -->
                    </v-list-item-content>
                </v-list-item>
                
            </v-list>
        </v-card-text>
        <v-card-actions>
            <v-row
                    align="center"
                    justify="space-around"
                >
                <v-btn
                    outlined
                    rounded
                    color="primary"
                    @click.stop="editItem"
                    v-if="!terminal.isCAR"
                    >
                    Editar
                    </v-btn>
            </v-row>
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>
</template>
<script>
export default {
    name: 'TerminalInfoDrawer',
    data: () => ({
        opened: false,
        terminal: null,
    }),
    methods:{
        editItem(){
            this.$emit('editionClicked', this.terminal); 
        },
        show(terminal){
            this.opened = true;
            this.terminal = terminal;
        }
    }
}
</script>
<style scoped>
.drawer-container{
    width: 350px !important;
}
</style>
<template>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      permanent
      color="primary">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            <v-img
              :src="require('@/assets/app/logo-ciphra-white.svg')"
              contain
              height="50"/>
          </v-list-item-title>
          <v-list-item-subtitle>
            &nbsp;
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list dense id="mainMenu">
        <v-list-item-group
          v-model="selectedItem"
          :mandatory="true"
          color="menu"
          v-if="isMaster && !businessActive">
          <v-list-item
            v-for="(item, i) in items"
            :key="item.key"
            @click="selectMod(i)">
            <v-list-item-icon>
              <unicon :name="item.icon" 
              fill="#FFFFFF"></unicon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-list-item-group
          v-model="selectedItem"
          :mandatory="true"
          color="menu"
          v-else>
          <v-list-item
            v-for="(item, i) in itemsBusiness"
            :key="item.key"
            @click="selectMod(i)">
            <v-list-item-icon>
              <unicon :name="item.icon" fill="#FFFFFF"></unicon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title >{{ item.text }}</v-list-item-title>

              <v-expand-transition>
                <v-list v-if="item.expanded">
                  <v-list-item
                    v-for="(subItem) in item.subItems"
                    :key="subItem.key"
                    @click.stop="selectMod(subItem.key)">
                    <v-list-item-content>
                      <v-list-item-title>{{ subItem.text }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expand-transition>
        
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

      </v-list>
      <template v-slot:append v-if="businessActive">
        <div class="pa-2">
          <v-btn text color="white" large id="signout-business-btn" @click.stop="signoutBusiness">
            {{businessName}} &nbsp;&nbsp;&nbsp;&nbsp;
            <unicon name="signout" fill="#FFFFFF"></unicon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
</template>
<script>
export default {
  data: () => ({
      drawer: null,
      selectedItem: 0,
      items: [
        { text: 'Dashboard', icon: 'apps', key: 0 },
        { text: 'Negocios', icon: 'bag' , key: 1},
        { text: 'Actividad', icon: 'traffic-barrier', key: 2 },
      ],
      itemsBusiness: [
        { text: 'Dashboard', icon: 'apps', key: 3 },
        { text: 'Transacciones', icon: 'transaction' , key: 4},
        { text: 'Prepagos', icon: 'credit-card', key: 5 },
        { text: 'Reembolsos', icon: 'receipt-alt', key: 6 },
        { text: 'POS', icon: 'shop', key: 7 },
        { text: 'Colaboradores', icon: 'users-alt', key: 8 },
        
        { text: 'Productos', icon:'users-alt', key: 9,
          expanded: false,
          subItems: [
            { text: 'Catálogo',  key: 10 },
            { text: 'Categorias', key: 11 },
            { text: 'Extras', key: 12 },
          ],
        },
        { text: 'Actividad', icon: 'traffic-barrier', key: 13 },
        { text: 'Ordenes', icon: 'traffic-barrier', key: 14 },
        { text: 'Cortes de caja', icon: 'traffic-barrier', key: 15 },
        
      ],
    }),
    computed:{
      isMaster(){
        return this.$store.getters.isMaster;
      },
      businessName(){
        return this.$store.getters.business && this.$store.getters.business.name;
      },
      businessActive(){
        return this.$store.getters.business && this.$store.getters.business._id;
      },
      isAdmin(){
        return this.$store.state.user && this.$store.state.user.role == 'admin';
      },
      isGrocer(){
        return this.$store.state.user && this.$store.state.user.role == 'grocer';
      },
     
    },
    watch: {
      // Observa los cambios en la ruta actual
      '$route'(to, from) {
        this.checkDashboardRoute();
      }
    },
   
    methods: {
      checkDashboardRoute() {
        if (this.$route.path === '/businessDashboard') {
          this.selectedItem = 0
        } 
        
        
      },
      signoutBusiness(){
        this.$store.dispatch('clearBusiness')
      },
      selectMenuOption(selected){
        if(selected == 10 || 
        selected == 11 ||
        selected == 12 
        ){ this.itemsBusiness[6].expanded = true;}
        this.selectedItem = selected;    
        console.log( this.selectedItem)    
       
      },
      selectMod(index) {
        this.selectedItem = index;
     
        if(this.isMaster && !this.businessActive){
          switch(index){
            case 0:
              this.$store.dispatch('savePath', 'home');
              //this.$router.push({ name: "home" });
            break;
            case 1:
              this.$store.dispatch('savePath', 'business');
              //this.$router.push({ name: "business" });
            break;
            default:
            //statements_def
            break;
          }
        }else{
          switch(index){
            case 0:
              this.$store.dispatch('savePath', 'businessDashboard');
            break;
            case 1:
              this.$store.dispatch('savePath', 'transactions');
            break;
            case 2:
              this.$store.dispatch('savePath', 'prepaids');
            break;
            case 3:
              this.$store.dispatch('savePath', 'refunds');
            break;
            case 4:
              this.$store.dispatch('savePath', 'pos');
            break;
            case 5:
              this.$store.dispatch('savePath', 'collaborators');
            break;
            case 6:
              const item = this.itemsBusiness[index];
              item.expanded = !item.expanded;
              this.$store.dispatch('savePath', 'catalogue');
            break;
            case 7:
              this.$store.dispatch('savePath', 'activity');
            break;
            case 8:
              this.$store.dispatch('savePath', 'orders');
            break;
            case 9:
              this.$store.dispatch('savePath', 'cuts');
            break;
            case 10:
              this.$store.dispatch('savePath', 'catalogue');
            break;
            case 11:
              this.$store.dispatch('savePath', 'category');
            break;
            case 12:
              this.$store.dispatch('savePath', 'extra');
            break;
            default:
            // statements_def
            break;
          }
        }
        
      }
    },
    created() {
      this.$bus.$on('selectCurrentMenuOption', this.selectMenuOption);
    }
};
</script>
<style scoped>


#mainMenu .v-list-item{
  color: #FFFFFF !important;
}
#mainMenu .v-list-item.v-item--active{
  color: #FFFFFF !important;
  background: #352359;
}
#mainMenu .v-list-item--active::before{
  opacity: 0.0 !important;
}


#signout-business-btn{
  width: 100% !important;
}
#signout-business-btn .unicon{
  position: absolute;
  right:0;
}
</style>
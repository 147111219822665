<template>
<v-dialog
      v-model="dialogOpen"
      persistent
      max-width="640"
    >
      <v-card class="form-tabs">
        <v-toolbar color="white" flat>
            <v-toolbar-title>{{isEdit ? 'Editar punto de venta':'Agregar punto de venta'}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogOpen = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <v-form ref="formItem" 
            @submit.prevent="submit"
            v-model="validData">
                <v-container>
                    <v-row>


                        <v-col cols="12">

                            <div class="form-row" style="margin-bottom: 20px;">

            <div class="container-input-image">
            <img :src="imageUrl" class="img-selected-form" />
            <div class="button-add-img" @click="selectFile">
                <v-icon color="white" x-large>add</v-icon>
            </div>
            <input type="file" 
                ref="fileInput"
                @change="onFileChange" 
                accept="image/*"
                style="display: none;"/>
            </div>



</div>

                        </v-col>

                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                            <v-text-field
                            v-model="item.name"
                            :rules="rules.required"
                            label="Nombre del punto de venta"
                            required
                            dense
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                            <v-text-field
                            v-model="item.description"
                            :rules="rules.required"
                            label="Breve descripción"
                            placeholder="Breve descripción (máx. 45 caracteres)"
                            required
                            counter
                            maxlength="45"
                            dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
             </v-form>
        </v-card-text>
        
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogOpen = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="black"
            rounded
            :dark="validData"
            :loading="isSavingData"
            :disabled="!validData"
            @click="saveData()"
          >
            Guardar
          </v-btn>
        </v-card-actions>
    </v-card>
    <snack-bar-message ref="snackMessages"/>
</v-dialog>
</template>
<script>
import axios from 'axios';
import SnackBarMessage from './SnackBarMessage.vue';


export default {
    components: {
        SnackBarMessage
    },
    data () {
      return {

        imageData:  null,
        imageUrl: require('../../assets/app/upload-img.svg'),

        validData: false,
        dialogOpen: false,
        isSavingData: false,
        isEdit: false,
        item: {
            business: null,
            name: null,
            description: null,
            colaborators: [],
        },
        rules: {
            required: [
                v => !!v || 'Campo requerido.',
            ],
        }
      }
    },
    computed: {
      activeBusiness(){
        return this.$store.getters.business;
      },
    },
    methods: {
    selectFile() {
      this.$refs.fileInput.click();
    },

    onFileChange(event) {
      const file = event.target.files[0]; // Obtiene el archivo seleccionado
      if (file && file.type.startsWith('image/')) {
        this.imageData = file
        this.imageUrl = URL.createObjectURL(file);  // Crea una URL de la imagen para vista previa
      } else {
        alert("Por favor selecciona un archivo de imagen válido.");
      }
    },



        reset () {
            this.$refs.formItem.reset()
            delete this.item._id;
        },
        resetValidation () {
            this.$refs.formItem.resetValidation()
        },
        open(){
            this.dialogOpen = true;
            this.reset();
            this.resetValidation();

            delete this.item._id;
            this.isEdit = false;
        },
        edit(item){
            this.dialogOpen = true;
            this.item = item;
            this.isEdit = true;
        },
        saveData(){
            this.isSavingData = true;
            this.item.business = this.activeBusiness._id;
            
            const saveUrl = this.isEdit ? 'terminals/update' : 'terminals/add';
            axios.post(saveUrl,this.item).then(response => {
                this.isSavingData = false;
                this.reset();
                this.$bus.$emit('refreshTerminalsList', true);
                this.dialogOpen = false;
            }).catch(err => {
                this.isSavingData = false;
            });
        },
    },
    created(){
    }
  }
</script>
<style scoped>
 .form-tabs .v-tabs-items{
    overflow: hidden !important;
    padding-top: 10px !important;
    height: 325px !important;
}
.form-tabs .row{
    margin: initial !important;
}
.form-tabs textarea{
    height: 230px !important;
}
.v-text-field__prefix{
    color: #000000 !important;
    font-weight: bold !important;
}
</style>
<template>
  <div class="section-container">
    
    <div class="section-header">
      <h1  class="section-title">
        Órdenes
      </h1>
      <div class="section-filter">
        <v-text-field
          class="section-search"
          hide-details
          placeholder="Buscar"
          single-line
          v-model="currentSearch"
          clearable>
          <template v-slot:append>
            <unicon name="search" fill="#5A5A5A"></unicon>
          </template>
        </v-text-field>
        <div class="section-filter-down">
          <div class="section-filter-element">
            <span>POS:</span>
            <v-select
              class="select-filter"
              dense
              v-model="selected_filter_pos"
              :items="filter_pos"
              item-text="text"
              item-value="value">
            </v-select>
          </div>
        </div>
      </div>
    </div>    

    <div class="section-table">
      <v-data-table
        :headers="headers"
        :items="orders"
        :items-per-page="orders.length"
        hide-default-footer>
        <template #item="{ item }">
          <tr>
            <td>{{ formattedDate(item.createdAt) }}</td>
            <td>{{ item.folio }}</td>
            <td>{{ item.products.length }}</td>
            <td>{{ item.products.length }}</td>
            <td>NFC</td>
            <td>{{ item.amount }}</td>
            <td>{{ item.order_status }}</td>
          </tr>
        </template>
      </v-data-table>
      <div v-if="showBtnMore"  class="btnMoreData" >
        <span @click="getOrders(true)">Mostrar mas +</span>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'CyphraWebOrders',
  data() {
    return { 
      currentSearch: '',
      selected_filter_pos: "todos",
      filter_pos: [
        { value: 'todos', text: 'Todos' },
      ],
      headers: [
        { text: 'Creado', value: 'createdAt' },
        { text: 'Folio', value: 'folio' },
        { text: 'Tiempo entrega', value: 'time' },
        { text: 'Cant. Productos', value: 'products' },
        { text: 'Pago', value: 'payment' },
        { text: 'Monto', value: 'amount' },
        { text: 'Estatus', value: 'status' },
      ],
      orders: [],
      businessId:"",
      currentPage:1,
      totalPage:0, 
      showBtnMore:false
    };
  },
  watch: {
    selected_filter_pos(newVal, oldVal) {
      this.getOrders(false)  
    },
    currentSearch(newVal, oldVal) {
      this.getOrders(false)  
    },
  },
  created () {
    this.loadListeners();
  },
  mounted() { 
    this.$bus.$emit('selectCurrentMenuOption',8);
    this.getInfo();
  },
  methods: { 

    loadListeners () {
      this.$bus.$off('refreshList');
      this.$bus.$on('refreshList', (isLoading) => {  
        this.getInfo();
      });
    },

    async getInfo(){
      const me = this
      me.orders=[]
      const userData = this.$store.state.user
      if(userData.role == "admin"){
        await axios.get('users/query?_id='+userData._id)
        .then(function (responseUser){
          me.businessId = responseUser.data.busines
        })
      }else{
        me.businessId = (this.$store.state.business)._id
      }
      me.getPostList(me.businessId)
    },

    getPostList(bussinesId){
      const me = this
      axios.get('terminals/listpost?business_id='+bussinesId)
        .then(function (responsePos){
          for(const item of responsePos.data){
            me.filter_pos.push({
              value: item._id, text: item.name
            })
          }
          me.getOrders(false)
        })
    }, 

    getOrders(isMore){
      const me = this
      let urlApi = ''
      let page = isMore ? this.currentPage + 1 : 1
      if(this.selected_filter_pos == "todos"){
        urlApi = 'orders/listByBusiness?id=' + this.businessId +
                '&page=' + page +
                '&limit=20' +
                '&search=' + this.currentSearch
      }else{
        urlApi = 'orders/listByPos?terminal_id=' + this.selected_filter_pos +
                '&page=' + page +
                '&limit=20' +
                '&search=' + this.currentSearch
      }
      axios.get(urlApi)
      .then(function (responseOrders){
        if(!isMore) me.orders = []
        me.totalPage = responseOrders.data.totalPages
        me.currentPage = responseOrders.data.currentPage
        for(const item of responseOrders.data.orders){
          me.orders.push(item)
        }
        if(me.totalPage == me.currentPage) me.showBtnMore = false
        else me.showBtnMore = true
      } )
    },

    formattedDate(date){
      const fDate = this.dateHelpers.formatTable(date)
      return fDate;
    },

  },
};
</script>

<style scoped lang="scss">
  @import './../assets/styles/sections-app.css';
</style>
<template>
    <v-dialog 
        v-model="dialogOpen"
        persistent
        max-width="640">
      <v-card class="form-tabs">
        <v-toolbar color="white" flat>
            <v-toolbar-title>{{isEdit ? 'Editar categoria':'Agregar categoria'}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogOpen = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <v-form 
            ref="formItem" 
            @submit.prevent="submit"
            v-model="validData">
                <v-container>

                    <v-text-field
                        v-model="nameCategory"
                        :rules="rules.required"
                        label="Nombre de la categoría"
                        required
                        dense>
                    </v-text-field>

                    <v-select
                        v-model="postSelected"
                        :items="terminals"
                        item-text="text"
                        item-value="value"
                        label="Terminal de venta u operación"
                        dense
                        :rules="rules.required">
                    </v-select>

                   
                </v-container>
            </v-form>
        </v-card-text>
        
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogOpen = false">
            Cancelar
          </v-btn>
          <v-btn
            color="black"
            rounded
            :dark="validData"
            :loading="isSavingData"
            :disabled="!validData"
            @click="saveData()">
            Guardar
          </v-btn>
        </v-card-actions>
    </v-card>
    <snack-bar-message ref="snackMessages"/>
</v-dialog>
</template>

<script>
import axios from 'axios';
import SnackBarMessage from './SnackBarMessage.vue';

export default {
    props: ["terminals"],
    components: {
        SnackBarMessage
    },
    name: 'CyphraWebAddEditCategoryModal',

    data() {
        return {
            dialogOpen: false,

            isEdit: false,

            validData:false,
            isSavingData:false,
            businessId:"",
            nameCategory:"",
            postSelected:"",
            category:null, 
            listTerminals:[],
           
            rules: {
                required: [
                    v => !!v || 'Campo requerido.',
                ],
            }
        };
    },

    mounted() { },
    

    methods: {

        open(item,business){
            this.dialogOpen = true;
            if(item){
                this.isEdit = true
                this.nameCategory = item.category_name 
                this.postSelected = item.terminal_id._id
                this.category = item
            }else{
                this.businessId = business
                this.isEdit = false
                this.nameCategory =  ""
                this.postSelected = ""
            }
        },

        saveData(){
            const userData = this.$store.state.user
            const saveUrl = this.isEdit ? 'categories/update' : 'categories/add';
            const bodyData = this.isEdit ? {
                edited_by:userData._id,
                category_name:this.nameCategory,
                terminal_id:this.postSelected,
                _id:this.category._id
            } :{
                edited_by:userData._id,
                category_name:this.nameCategory,
                terminal_id:this.postSelected,
                buiness_id:this.businessId,
           }
            axios.post(saveUrl,bodyData).then(_ => {
                this.isSavingData = false;
                this.reset();
                this.$bus.$emit('refreshCategoryList', true);
                this.dialogOpen = false;
            }).catch(_ => {
                this.isSavingData = false;
                this.$refs.snackMessages.show('Ocurrio un error al guardar. Asegurese llenar todos los campos requeridos.');
            });
        },

        reset () {
            this.$refs.formItem.reset()
            this.$refs.formItem.resetValidation()
        },
        
    },
};
</script>

<style  scoped>

.form-tabs .v-tabs-items{
    overflow: hidden !important;
    padding-top: 10px !important;
    height: 325px !important;
}
.form-tabs .row{
    margin: initial !important;
}
.form-tabs textarea{
    height: 230px !important;
}
.v-text-field__prefix{
    color: #000000 !important;
    font-weight: bold !important;
}

</style>
<template>
<v-layout justify-center>
      <v-flex xs12 sm12 md12 lg12 class="mt-12">
        <v-card flat color="background">
          <!-- Push Contents In and Put Space Between Flex Items Using Grid List -->
          <v-container fluid grid-list-lg>
            <toolbar-section title="Negocios" placeholderSearch="Buscar por nombre" @searchItem="searchItemData" @filterBusinessChanged="filterWasChanged"/>
            <v-layout row wrap v-if="itemsFiltered.length>0">
              <!--  Create Cards Based on Objects in an Array -->
              <v-flex class="card-item" 
              v-for="item in itemsFiltered" v-bind="{ [`lg4 md4 xl4 sm6`]: true }" 
              :key="item._id">
                <v-card flat @click.native="showItemInfo(item)" class="elevation-4">
                  <v-list color="cards" rounded two-line>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-badge
                          bottom
                          bordered
                          dot
                          :color="item.status == 0 ? 'error':'green'"
                          offset-x="14"
                          offset-y="14">
                          <v-avatar size="60">
                            <v-img
                            :key="item.updatedAt"
                            :src="renderImage(item.logo)"/>
                          </v-avatar>
                        </v-badge>
                        <!-- 
                        <img
                          src="https://picsum.photos/1920/1080?random"
                          alt="image" > 
                        -->
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title @click.stop="viewBusiness(item)" class="item-title"><b>{{item.name}}</b></v-list-item-title>
                        <v-list-item-subtitle>{{item.category.name}}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{item.city}}</v-list-item-subtitle>
                      </v-list-item-content>
                      
                      <v-list-item-action >
                        <v-menu bottom
                          origin="center center"
                          transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                              icon
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                <unicon name="ellipsis-v" fill="#5A5A5A"></unicon>
                              </v-btn>
                            </template>
                            <v-list dense>
                              <v-list-item @click.stop="editItem(item)">
                                <v-list-item-title>Editar</v-list-item-title>
                              </v-list-item>
                              <v-list-item @click.stop="toggleActivationItem(item)">
                                <v-list-item-title>{{ item.status ? 'Bloquear': 'Reactivar' }}</v-list-item-title>
                              </v-list-item>
                              <v-list-item @click.stop="deleteItem(item)">
                                <v-list-item-title>Eliminar</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-flex>
            </v-layout>
            
            <no-results v-else />

            <confirm-modal ref="confirm" @interface="componentConfirmResult" />
            <business-info-drawer ref="drawerBusiness" @editionClicked="editItem"/>

          </v-container>
          <floating-button @clickFloating="addNewBusiness" icon="add" color="black"/>
        </v-card>
      </v-flex>

      <add-edit-business-modal ref="addEditModal"/>
    </v-layout>
</template>
<script>
  import axios from 'axios';
  import ToolbarSection from './Toolbars/ToolbarSection'
  import FloatingButton from './Toolbars/FloatingButton'
  import AddEditBusinessModal from './Dialogs/AddEditBusinessModal'
  import ConfirmModal from './Dialogs/ConfirmModal'
  import BusinessInfoDrawer from './Drawers/BusinessInfoDrawer'
  import NoResults from './NoResults'

  const REQUEST_ITEM_DELETE = 1;
  const REQUEST_ITEM_TOGGLE_ACTIVATE = 2;
  export default {
    components: {
      ToolbarSection,
      FloatingButton,
      AddEditBusinessModal,
      ConfirmModal,
      BusinessInfoDrawer,
      NoResults
    },
    data: () => ({
      filterSelected: -1, //Todos los items
      business: [],
      currentSearch: '',
      publicPath: process.env.VUE_APP_URL
    }),

    created () {
      this.initialize();
      this.loadBusinessListeners();
    },

    computed:{
      itemsFiltered(){
        let items = this.business;
        if(this.filterSelected >= 0){
          items = this.business.filter(item => item.status == this.filterSelected);
        }
        return items;
      },
    },

    mounted() {
      //this.$store.dispatch('savePath', 'business');
      this.$bus.$emit('selectCurrentMenuOption',1);
    },

    methods: {
      viewBusiness(business){
        this.$store.dispatch('saveBusiness', business)
      },
      filterWasChanged(selectedFilter){
        this.filterSelected = selectedFilter;
      },
      renderImage(image){
          const imgPath = this.publicPath+'images/uploads/business/' + image;
        return imgPath;
      },
        loadBusinessListeners () {
              this.$bus.$off('refreshBusinessList');

              this.$bus.$on('refreshBusinessList', (isLoading) => {
                this.listBusiness();
              });

        },
        showItemInfo(item){
          this.$refs.drawerBusiness.show(item);
        },
        deleteItemConfirmed(item_id, isConfirmated){
          if(isConfirmated){
            this.$bus.$emit('toggleLoading', true);
            axios.post('business/delete',{item_id}).then(response => {
                this.$bus.$emit('toggleLoading', false);
                this.listBusiness();
            }).catch(err => {

            });
          }
        },
        toggleActivationItemConfirmed(item_id, isConfirmated){
          if(isConfirmated){
            this.$bus.$emit('toggleLoading', true);
            axios.patch('business/toggleActivation',{item_id}).then(response => {
                this.$bus.$emit('toggleLoading', false);
                this.listBusiness();
            }).catch(err => {

            });
          }
        },
        componentConfirmResult(isConfirmated, item_id, component){
          switch (component) {
                case REQUEST_ITEM_DELETE :
                  this.deleteItemConfirmed(item_id, isConfirmated);
                break;
                case REQUEST_ITEM_TOGGLE_ACTIVATE :
                  this.toggleActivationItemConfirmed(item_id, isConfirmated);
                break;
            }
        },
        editItem(item){
          const business = {...item};
          this.$refs.addEditModal.edit(business);
        },
        deleteItem(item){
          let data = {
              title: "Eliminar",
              message: `¿Deseas bloquear y eliminar a <<b>${item.name}</b>>? No podrás deshacer la acción.`,
              confirmText: "Si, Eliminar"
          }
          this.$refs.confirm.open(data, REQUEST_ITEM_DELETE, item._id);
        },
        toggleActivationItem(item){
          const message = item.status ? `Colaboradores de <<b>${item.name}</b>> no podrán usar la plataforma. ¿Deseas continuar?`
          : `Colaboradores de <<b>${item.name}</b>> podrán usar nuevamente Ciphra. ¿Deseas continuar?`;
          let data = {
              title: item.status ? "Bloquear negocio" : "Reactivar negocio",
              message: message,
              confirmText: item.status ? "Si, Bloquear" : "Si, Reactivar"
          }
          this.$refs.confirm.open(data, REQUEST_ITEM_TOGGLE_ACTIVATE, item._id);
        },
        addNewBusiness(){
          this.$refs.addEditModal.open();
        },
        searchItemData(search){
          this.currentSearch = search;
          this.listBusiness();
        },
        listBusiness(){
            const search = this.currentSearch;
            this.$bus.$emit('toggleLoading', true);
            axios.get(`business/list?search=${search}`).then(response => {
                this.business = response.data;
                this.$bus.$emit('toggleLoading', false);
            }).catch(err => {

            });
        },
        initialize () {
            this.listBusiness();
        },
    },
  }
</script>
<style scoped>
 .flex-empty div {
  width: 350px;
}
.card-item{
  min-width: 320px !important;
  /*flex-basis: initial !important;
  max-width: initial !important;*/
}
#card-container, .data-containe{
  height: 55vh;
  overflow: auto;
  width: 100%;
  padding-right: 0 !important;
  margin-right: 0 !important;
}
.v-list-item__action{
  margin-left: 0 !important;
}
.item-title:hover{
  text-decoration: underline;
}

</style>
<template>
    <v-navigation-drawer
      v-model="opened"
      height="100vh"
      right
      floating
      fixed
      temporary
      hide-overlay
      class="drawer-container"
    >
      <v-card flat v-if="transaction">
        <v-toolbar dense flat>
            <v-toolbar-title>Detalles</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="opened = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <v-list>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Creada</v-list-item-title>
                        <v-list-item-subtitle>{{ creationDate(transaction.createdAt) }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>ID transacción</v-list-item-title>
                        <v-list-item-subtitle>{{ transaction.transaction_id }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>ID tag</v-list-item-title>
                        <v-list-item-subtitle>{{ transaction.tag_id }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-layout justify-center>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Operación</v-list-item-title>
                                <v-list-item-subtitle>{{ transaction.transaction_type.name }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Estatus</v-list-item-title>
                                <v-list-item-subtitle>{{ transaction.status == 1 ? 'Realizada' : 'Cancelada' }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                </v-layout>
                <v-layout justify-center>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Método</v-list-item-title>
                                <v-list-item-subtitle>{{ transaction.payment_method.name }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Clave Prepago</v-list-item-title>
                                <v-list-item-subtitle>-</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                </v-layout>
                <v-layout justify-center>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Monto</v-list-item-title>
                                <v-list-item-subtitle>$ {{ transaction.amount.toFixed(2) }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Saldo</v-list-item-title>
                                <v-list-item-subtitle>$ {{ transaction.tag_balance.toFixed(2) }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                </v-layout>


                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Registro</v-list-item-title>
                        <v-list-item-subtitle>{{ transaction.collaborator.name }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Terminal</v-list-item-title>
                        <v-list-item-subtitle>{{ transaction.terminal.name }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card-text>
        
      </v-card>
    </v-navigation-drawer>
</template>
<script>
import moment from 'moment'
export default {
    name: 'TransactionInfoDrawer',
    data: () => ({
        opened: false,
        transaction: null,
    }),
    methods:{
        editItem(){
            //this.$emit('editionClicked', this.collaborator); 
        },
        show(transaction){
            this.opened = true;
            this.transaction = transaction;
        },
        creationDate(date){
            //const fDate = moment(date).utcOffset(0).format('DD MMMM YYYY HH:mm:ss');
            const fDate = this.dateHelpers.formatTable(date);
            return fDate;
        }
    }
}
</script>
<style scoped>
.drawer-container{
    width: 350px !important;
}
</style>
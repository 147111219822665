<template>
<v-container class="bc">

<v-col class="mb-4">
  <h1 class="display-1 font-weight-bold mb-3 mt-3">
    Disco Attack
  </h1>
</v-col>

<div class="container-dash mb-4">

  <v-col>
    <h3 class="font-weight-bold mb-3">
      Operaciones del año
    </h3>
  </v-col>

  <v-row class="text-center ">
    <v-col cols="3">
      <v-col cols="12">
        <h3 class="font-weight-bold">
          {{activeTags}}
        </h3>
      </v-col>
      <span class="mb-3">
          Pulseras activadas
      </span>
    </v-col>
      <v-col cols="3">
        <v-col cols="12">
        <h3 class="font-weight-bold">
          ${{totalRecharge}}
        </h3>
      </v-col>
      <span class="mb-3">
          Ingresos por recargas
      </span>
    </v-col>
    <v-col cols="3">
      <v-col cols="12">
        <h3 class="font-weight-bold">
          ${{totalPayment}}
        </h3>
      </v-col>
      <span class="mb-3">
          Consumos con pulsera
      </span>
    </v-col>
    <v-col cols="3">
      <v-col cols="12">
        <h3 class="font-weight-bold">
          ${{totalRefund}}
        </h3>
      </v-col>
      <span class="mb-3">
          Reembolsos pagados
      </span>
    </v-col>
  </v-row>

  <v-row class="text-center ">
    <v-col cols="12">
      <div class="ct-chart">
        <canvas id="myChart"/>
      </div>
    </v-col>
  </v-row>

</div>
<div class="container-dash mb-4">

  <v-col>
    <h3 class="font-weight-bold mb-3">
      Tendencias
    </h3>
  </v-col>

  <v-row class="text-center ">
    <v-col cols="6">
      <span class="mb-3">
        Recargas
      </span>
      <v-col cols="12">
        <div class="ct-chart">
          <canvas id="myChartdonut1">
          </canvas>
        </div>
      </v-col>
    </v-col>
    <v-col cols="6">
      <span class="mb-3">
        Reembolsos
      </span>
      <v-col cols="12">
        <div class="ct-chart">
          <canvas id="myChartdonut2">
          </canvas>
        </div>
      </v-col>
    </v-col>
  </v-row>
</div>
</v-container>
  
</template>


<script>
import axios from 'axios'
import Chart from 'chart.js'

export default {
    data(){
      return{
        activeTags:0,
        totalRecharge:0,
        totalPayment:0,
        totalRefund:0,
        rechargeMonth:[0,0,0,0,0,0,0,0,0,0,0,0],
        paymentMonth:[0,0,0,0,0,0,0,0,0,0,0,0],
        refundMonth:[0,0,0,0,0,0,0,0,0,0,0,0],
        myChart:null,
        chartRecharge:null,
        chartRefound:null,
        recharge:[0,0,0],
        refund:[0,0],
      }
    },

    methods:{
      getData(){
        let me=this;
        
        const userData = this.$store.state.user
     
        if(userData.role == "admin"){
          axios.get('users/query?_id='+userData._id)
          .then(function (responseUser){
          
            axios.post('business/admin',{business:responseUser.data.business} )
            .then(function (response){
                console.log(response.data)
                me.activeTags = response.data.active;
                me.totalRecharge = response.data.totalRecharge;
                me.totalPayment = response.data.totalPayment;
                me.totalRefund = response.data.totalRefund;
                response.data.rechargeMonth.map( (item, index) =>{
                  me.rechargeMonth[index] = item
                });
                response.data.paymentMonth.map( (item, index) =>{
                  me.paymentMonth[index] = item
                });
                response.data.refundMonth.map( (item, index) =>{
                  me.refundMonth[index] = item
                });
                me.recharge[0]=response.data.recharge.cash;
                me.recharge[1]=response.data.recharge.card;
                me.recharge[2]=response.data.recharge.prepaid;
                me.refund[0]=response.data.refund.cash;
                me.refund[1]=response.data.refund.transfer;
                me.myChart.update();
                me.chartRecharge.update();
                me.chartRefound.update();
            }).catch(function(error){ console.log(error); });

          })

        }else{
          axios.post('business/admin',{business:this.$store.state.business} )
          .then(function (response){
              console.log(response.data)
              me.activeTags = response.data.active;
              me.totalRecharge = response.data.totalRecharge;
              me.totalPayment = response.data.totalPayment;
              me.totalRefund = response.data.totalRefund;
              response.data.rechargeMonth.map( (item, index) =>{
                me.rechargeMonth[index] = item
              });
              response.data.paymentMonth.map( (item, index) =>{
                me.paymentMonth[index] = item
              });
              response.data.refundMonth.map( (item, index) =>{
                me.refundMonth[index] = item
              });
              me.recharge[0]=response.data.recharge.cash;
              me.recharge[1]=response.data.recharge.card;
              me.recharge[2]=response.data.recharge.prepaid;
              me.refund[0]=response.data.refund.cash;
              me.refund[1]=response.data.refund.transfer;
              me.myChart.update();
              me.chartRecharge.update();
              me.chartRefound.update();
          }).catch(function(error){
              console.log("X)X)");
              console.log(error);
          });

        }


        


     
      },

      graficar(){
        let me=this;
        var ctx = document.getElementById('myChart');
        me.myChart = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: ["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"],
              datasets: [{
                    type: 'bar',
                    backgroundColor: "rgba(0,237,213,1)",
                    borderColor: "rgba(0,237,213,1)",
                    label: 'Ingresos',
                    data: me.rechargeMonth,
                    borderWidth: 1
                },
                {
                   type: 'bar',
                    label: 'Consumos',
                    backgroundColor: "rgba(121,84,194,1)",
                    borderColor: "rgba(121,84,194,1)",
                    data: me.paymentMonth,
                    borderWidth: 1
                },
                {
                   type: 'bar',
                    label: 'Reembolsos',
                    backgroundColor: "rgba(179,179,179,1)",
                    borderColor: "rgba(179,179,179,1)",
                    data: me.refundMonth,
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
      },

      DonaRecharge(){
        let me=this;
        var ctx = document.getElementById('myChartdonut1');
        
        me.chartRecharge = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: ["Efectivo","Tarjeta","Prepago"],
                datasets: [{
                    label: 'Activaciones por mes',
                    data: me.recharge,
                    backgroundColor: [
                        'rgba(0,237,213,1)',
                        'rgba(121,84,194,1)',
                        'rgba(179,179,179)'
                    ],
                    borderColor: [
                        'rgba(0,237,213,1)',
                        'rgba(121,84,194,1)',
                        'rgba(179,179,179)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
              legend: {
                  display: false
              },
            }
            
        });
        
      },

      DonaRefound(){
        let me=this;
        var ctx = document.getElementById('myChartdonut2');
        me.chartRefound = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: ["Efectivo","Transferencia"],
                datasets: [{
                    label: 'Activaciones por mes',
                    data: me.refund,
                    backgroundColor: [
                        'rgba(0,237,213,1)',
                        'rgba(121,84,194,1)',
                    ],
                    borderColor: [
                        'rgba(0,237,213,1)',
                        'rgba(121,84,194,1)',
                        'rgba(179,179,179)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
              legend: {
                  display: false
              },
            }

        });
      },




/*

       StackedBar(){
        var ctx2 = document.getElementById('myChart2');
        var myChart2 = new Chart(ctx2, {
            type: 'bar',
            data: {
                labels: ["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"],
                datasets: [{
                    backgroundColor: "rgba(0,237,213,1)",
                    label: "Ingresos",
                    data: [3000,3000,3000,3000,3000,3000,3000,3000,3000,3000,3000,3000],
                },
                {
                    label: "Consumos",
                    backgroundColor: "rgba(121,84,194,1)",
                    data: [7000,7000,7000,7000,7000,7000,7000,7000,7000,7000,7000,7000],
                }]
            },
            options: {
              scales: {
                xAxes: [{
                  stacked: true
                }],
                  yAxes: [{
                  stacked: true
                }]
              }
            }
        });
      },

      */
    },
    mounted(){
      this.getData();
      this.graficar();
     // this.StackedBar();
      this.DonaRefound();
      this.DonaRecharge();
    }
}


</script>

<style>
.bc{
  width: 80vw;
}

#myChart{
  width: 100%;
}

.container-dash{
  background: white;
  width: 100%;
  padding: 20px;
}
</style> 

import { render, staticRenderFns } from "./Cuts.vue?vue&type=template&id=0e7970a8&scoped=true&"
import script from "./Cuts.vue?vue&type=script&lang=js&"
export * from "./Cuts.vue?vue&type=script&lang=js&"
import style0 from "./Cuts.vue?vue&type=style&index=0&id=0e7970a8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e7970a8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VDataTable,VSelect,VTextField})

<template>
    <v-card dense flat >
        <v-app-bar color="background" id="custom-app-bar" extended
      flat>
                    <v-toolbar-title>{{title}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-text-field
                        class="search-field"
                        v-model="searchValue"
                        hide-details
                        :placeholder="placeholderSearch"
                        single-line
                        clearable
                        @click:clear="clearSearch"
                        @keydown.enter="searchChanged"
                        >
                        <template v-slot:append>
                            <unicon name="search" fill="#5A5A5A" v-on:click="searchChanged"></unicon>
                        </template>
                    </v-text-field>
        </v-app-bar>
    </v-card>
</template>
<script>
export default {
    name: "ToolbarSection",
    props: {
        title: String,
        placeholderSearch: String
    },
    data: () => ({
        searchValue: '',
    }),
    methods: {
        clearSearch(){
            this.searchValue = '';
            this.searchChanged();
        },
        searchChanged(){
            this.$emit("searchItem", this.searchValue);
        }
    }
}
</script>
<style scoped>
</style>